/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import { Avatar } from 'components/user';
import { get_api_products } from 'components/api';

// import logo from './img/logo-pc-new.png';

import { PortraitNav } from 'components/portrait-nav';
import { TcIconLogoComplete } from 'components/IconSystem';

export function Header(props) {
  const [isShow, setIsShow] = useState(false);
  const [product, setProduct] = useState(null);
  useEffect(() => {
    window.addEventListener('click', onClickWindow, false);

    return window.removeEventListener('click', onClickWindow, false);
  }, []);

  useEffect(() => {
    if (props.user && props.user.id) {
      getNormalProduct();
    }
  }, [props.user]);

  const getNormalProduct = () => {
    get_api_products().then((res) => {
      if (res.data instanceof Array) {
        setProduct(() => {
          const normalProduct = res.data.filter(item => item.credit_level === '');
          return normalProduct[0];
        });
      }
    })
      .catch((error) => {
        console.log(error);
      });
  };

  const onClickWindow = () => {
    setIsShow(false);
  };

  const onShowNav = (e) => {
    e.stopPropagation();
    setIsShow(!isShow);
  };

  return (
    <div className={classNames('header', { 'header--absolute': !props.indexStyle })}>
      <div className="header_inner">
        {props.showHeaderLogo && (
          <div className="logo_area">
            <a href="/">
              <TcIconLogoComplete />
            </a>
          </div>
        )}
        {props.showHeaderRight && (
          <>
            {!isEmpty(props.user) ? (
              <div
                className="user_area"
                role="button"
                tabIndex="0"
                onClick={onShowNav}
                onKeyDown={() => {}}
              >
                <Avatar src={props.user.avatar} />
                <PortraitNav showSelect={isShow} product={product} user={props.user} />
              </div>
            ) : (
              <a className="login_in" href={props.loginUrl}>
                登录
              </a>
            )}
            <div className="service_area">
              <a href="/helper" className="link_help">
                文档
              </a>
            </div>
            <div className="service_area">
              <a href="/products/1368/blog/506989" className="link_help">
                更新日志
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

Header.propTypes = {
  onClickCreate: PropTypes.func.isRequired,
  loginUrl: PropTypes.string.isRequired,
};

Header.defaultProps = {
  showHeaderLogo: true,
  showHeaderRight: true,
  indexStyle: true,
};
