/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';
import { postApiV2Logout } from 'components/api';
import classnames from 'classnames';
import './index.less';
import { getEnterpriseEntry } from 'components/util/enterprise-entry';

const PortraitNav = (props) => {
  const onLogOut = () => {
    postApiV2Logout().then(res => location.reload());
  };

  const isCompany =  props.user.cid > 0 && getEnterpriseEntry();

  return (
    <ul className={classnames('user_area_nav', {
      slide_show: props.showSelect,
      is_company: isCompany,
    })}>
      <li>
        <a href="/dashboard/new-product">
          <i className="icon_add"></i> 新建
        </a>
      </li>
      <li>
        <a href={'/dashboard/products'}>
          <i className="icon_product"></i> 我的产品
        </a>
      </li>
      {isCompany && (
        <li className="enterprise_entry">
          <a href={`/enterprise/${props.user.cid}/home`} target="_blank" rel="noreferrer">
            <i className="icon_enterprise" /> 腾讯惜议
          </a>
        </li>
      )}

      <li>
        <a href="/">
          <i className="icon_home"></i> 官网
        </a>
      </li>
      <li>
        <a href="/products/1368">
          <i className="icon_feedback"></i> 反馈
        </a>
      </li>
      <li className="logout" onClick={onLogOut}>
        <i className="icon_exit"></i> 退出登录
      </li>
    </ul>
  );
};

PortraitNav.propTypes = {
  user: PropTypes.object,
  showSelect: PropTypes.bool,
};

export { PortraitNav };
